import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import RecordsTable from './components/recordsTable';
import getEnvironement from '../../environnement';
import { userSelector } from '../../store/userSlice';
import searchIcon from '../../assets/icons/search-normal.png';
import DashHeader from './components/dashHeader';

function Records() {
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const [products, setProducts] = useState(user.products);

  const handelChangeSearchInput = (e) => {
    setProducts(user.products.filter(product => product.product_id.startsWith(e.target.value)));
  };
  return (
    <div>
      <DashHeader picture={`${getEnvironement().BACKEND_URL}/storage/` + user?.profile_picture} name={user?.nom} />
      <div className='flex justify-between items-center mb-5'>
        <p className='font-poppins font-medium text-2xl'>Tous les produits</p>
        <Button variant='contained' onClick={() => navigate('/')}>+ Ajouter un nouveau produit</Button>
      </div>
      <div className='flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg'>
        <img src={searchIcon}/>
        <input
          type='search'
          placeholder='Chercher par ID'
          onChange={(e) => handelChangeSearchInput(e)}
          className='bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full'
        />
      </div>
      <RecordsTable
        header={['id-produit', 'type de produit', 'pack', 'Date', 'Status', 'Actions']}
        data={products}
      />
    </div>
  );
}

export default Records;